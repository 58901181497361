import { useMemo } from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import { useSelector } from 'react-redux';

export type AssociationSalesChannel = {
  salesChannelId: string;
  salesChannelName: string;
  salesChannelType?: string;
  storeId: number;
  brandId?: string;
  brandName?: string;
  menuSource?: string;
  menuId?: string;
};

const useStoreToSalesChannelTransformerWithQuery = () => {
  const appId = useSelector((state: AppState) => state.currentApp.AppId) ?? '';

  const { data: rmsCommonData } = useQueryRMSCommonDataHook(appId);
  const associations = rmsCommonData?.associations;

  return useMemo(() => {
    const associationSalesChannels: Array<AssociationSalesChannel> = [];

    if (!associations?.orgHierarchy) return associationSalesChannels;

    const brandMap = new Map(
      associations.orgHierarchy.brands.map((brand) => [
        brand.id,
        { code: brand.code, name: brand.name },
      ])
    );

    associations.orgHierarchy.properties.forEach((property) => {
      property.storefronts.forEach((storefront) => {
        if (storefront.fieldAssociations?.flipdish?.storeId && !storefront.isArchived) {
          const brandInfo = brandMap.get(storefront.brandId);

          associationSalesChannels.push({
            salesChannelId: storefront.code,
            salesChannelName: storefront.name,
            salesChannelType: storefront.type,
            storeId: Number(storefront.fieldAssociations.flipdish.storeId),
            brandId: brandInfo?.code,
            brandName: brandInfo?.name,
            menuSource: storefront.menuSource,
            menuId: storefront.menuId,
          });
        }
      });
    });

    return associationSalesChannels;
  }, [associations]);
};

export default useStoreToSalesChannelTransformerWithQuery;
